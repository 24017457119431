<template>
    <!--begin::Layout-->
    <div class="d-flex flex-column flex-lg-row notranslate" translate="no">
      <!--begin::Sidebar-->
      <div
        class="
          flex-column flex-lg-row-auto
          w-100 w-lg-300px w-xl-400px
          mb-10 mb-lg-0
        "
      >
        <!--begin::Contacts-->
        <div class="card card-flush">
          <!--begin::Card header-->
          <div class="card-header pt-7" id="kt_chat_contacts_header">
            <!--begin::Form-->
            <form class="w-100 position-relative" autocomplete="off">
              <!--begin::Input-->
              <input
                type="text"
                v-model="search"
                @input="getSessionList()"
                class="form-control form-control-solid"
                name="search"
                :placeholder="$t('modals.hotel.search')"
              />
              <!--end::Input-->
            </form>
            <!--end::Form-->
          </div>
          <!--end::Card header-->
  
          <!--begin::Card body-->
          <div class="card-body pt-5" id="kt_chat_contacts_body">
            <!--begin::List-->
            <div
              class="scroll-y me-n5 pe-5 h-550px"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header"
              data-kt-scroll-wrappers="#kt_content, #kt_chat_contacts_body"
              data-kt-scroll-offset="0px"
            >
            <div
              v-if="isLoading"
              style="
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div class="text-center px-5 pb-7">
                <img
                  alt="Logo"
                  class="max-h-25px"
                  src="/media/loading_icon.gif"
                  style="width: 35px"
                />
              </div>
            </div>
              <div v-else-if="originalSessionList.length > 0">
                <template v-for="(session, index) in sessionList" :key="index">
                  <div class="d-flex flex-stack py-4">
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Details-->
                      <div class="ms-5">
                        <router-link
                          :to="'/hotel-management/' + session.session.id"
                        >
                          <a
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >{{ session.session.title }}</a
                          >
                        </router-link>
                        <!--
                        <div class="fw-bold text-gray-400">test</div>-->
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
  
                    <!--begin::Lat seen-->
                    <div class="d-flex flex-column align-items-end ms-2">
                      <span class="text-muted fs-7 mb-1">{{
                        formatDate(session.session.createdAt)
                      }}</span>
                    </div>
                    <!--end::Lat seen-->
                  </div>
                </template>
              </div>
              <div v-else>
                <div class="d-flex flex-column align-items-center ms-2">
                  <span class="fs-7 mt-4" style="color: black">{{
                    $t("modals.hotel.zeroSession")
                  }}</span>
                </div>
              </div>
            </div>
            <div
              style="display: flex; justify-content: center; align-items: center"
            >
            <router-link
                          to="/hotel-management/"
                        >
              <a
                class="btn btn-md btn-success"
                style="margin-right: 5px"
                @click="startNewSession"
              >
              {{ $t("modals.hotel.startNewSession") }}
              </a>
          </router-link>
            </div>
            <div
              class="pt-2 pb-0"
              style="text-align: center; color: black; font-size: 12px"
            >
              <a>             {{ $t("modals.hotel.sessionCount") }}: {{ user.remainingQuota }} </a>
            </div>
            <!--end::List-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Contacts-->
      </div>
      <!--end::Sidebar-->
  
      <router-view></router-view>

    </div>
    <!--end::Layout-->
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref, watch } from "vue";
  import { useRoute } from "vue-router";
  import { ScrollComponent } from "../../../assets/ts/components";
  import { setCurrentPageTitle } from "../../../helper/Breadcrumb";
  import { SessionListModel } from "@/domain/hotel-management/session-list/model/SessionListModel";
  import { useStore } from "vuex";
  import SwalNotification from "@/presentation/plugins/SwalNotification";
  import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
  import { useI18n } from "vue-i18n";
import { AboutUserModel } from "@/domain/user/about-user/model/AboutUserModel";
import i18n from "@/presentation/language/i18n";
  
  interface KTMessage {
    type: string;
    name?: string;
    image: string;
    time: string;
    text: string;
  }
  
  export default defineComponent({
    name: "private-chat",
    setup() {
      const { t } = useI18n();
      const store = useStore();
      const route = useRoute();
      const isLoading = ref<boolean>(false);

      const sessionId = ref<any>(Number(route.params["id"]));
  
      const messagesRef = ref<null | HTMLElement>(null);
      const messagesInRef = ref<null | HTMLElement>(null);
      const messagesOutRef = ref<null | HTMLElement>(null);
  
      const swalNotification = new SwalNotification();
  
      const user = ref<AboutUserModel>({
      });

      const messages = ref<Array<KTMessage>>([]);
      const sessionTitle = ref<string>("");
      const sessionList = ref<SessionListModel[]>([]);
      const originalSessionList = ref<SessionListModel[]>([]);

      const sessionListModel: SessionListModel = {
        session: {
        productSessionMessages: []
      },
        page: 1,
        pageSize: 50,
      };
      const search = ref<string>("");
  
      const userController = store.state.ControllersModule.userController;
      const sessionController = store.state.ControllersModule.sessionController;
  
      const newMessageText = ref("");
  
      watch(
        () => store.getters.newChat,
        () => {
          if (store.getters.newChat) 
          getUserInfo();
          getSessionList();
        }
      );

      const getSessionList = () => {
        isLoading.value = true;

        sessionList.value = [];
        messages.value = [];
  
        if (search.value) {
          sessionListModel.query = search.value;
      } else {
        originalSessionList.value = [];
        sessionListModel.query = search.value;
      }

        sessionController
          .sessionList(sessionListModel)
          .then((response) => {
            if (response.isSuccess) {
              response.getValue().forEach((session) => {
                sessionList.value.push(session);
                originalSessionList.value.push(session);

                if (session.session.id == sessionId.value) {
                  sessionTitle.value = session.session.title
                  session.session.productSessionMessages.forEach((log, index) => {
                    messages.value.push({
                      type: log.owner === "USER" ? "out" : "in",
                      name: log.owner === "USER" ? undefined : "Asistan",
                      image:
                        log.owner === "USER"
                          ? "/media/avatars/blank.png"
                          : "/media/avatars/ai.jpg",
                      time: formatDate(log.createdAt),
                      text: log.text,
                    });
                  });
                }
              });
            } else {
              sessionList.value = [];
                originalSessionList.value = [];

              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
            isLoading.value = false;
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      };
  
      const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const now = new Date();
  
        const difference = now.getTime() - date.getTime();
  
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  
        if (days === 0) {
          const hours = Math.floor(difference / (1000 * 60 * 60));
          if (hours === 0) {
            const minutes = Math.floor(difference / (1000 * 60));
            return `${minutes} ` + i18n.global.t("common.times.minuteAgo");
          } else {
            return `${hours} ` + i18n.global.t("common.times.hourAgo");
          }
        } else {
          return `${days} ` + i18n.global.t("common.times.dayAgo");
        }
      };
  
      const getUserInfo = () => {
        userController
          .aboutUser()
          .then((response) => {
            if (response.isSuccess) {
              Object.assign(user.value, response.getValue());
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      };

      const startNewSession = () => {
        store.dispatch("startNewSession", !store.getters.startNewSession);
      };

      onMounted(() => {
        getUserInfo()
        getSessionList();
        setCurrentPageTitle(t("modals.hotel.title"));
  
        if (!messagesRef.value) {
          return;
        }
  
        ScrollComponent.bootstrap();
      });
  
      return {
        formatDate,
        startNewSession,
        messages,
        messagesRef,
        newMessageText,
        messagesInRef,
        messagesOutRef,
        sessionList,
        originalSessionList,
        sessionTitle,
        user,
        getSessionList,
        search,
        isLoading
      };
    },
  });
  </script>
  